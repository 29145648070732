import React from 'react'
import classNames from 'classnames'

import AndroidIcon from '@components/icons/android'
import AppleIcon from '@components/icons/apple'
import Link from '@components/link'
import styles from '@components/blocks/cta_block.module.scss'

class CtaBlock extends React.Component {
  render() {
    const fields = this.props.fields
    const classes = [styles.ctaBlock]
    if (fields.layout) {
      classes.push(styles[`ctaBlock--${fields.layout.replace(/\s/g, '')}`])
    }

    const backgroundColorClass = (backgroundColor => {
      switch (backgroundColor) {
        case 'Tan':
          return styles['ctaBlock--tan']
        case 'Light Blue':
          return styles['ctaBlock--light_blue']
        default:
          return null
      }
    })(fields.backgroundColor)

    const spacingClass = (spacing => {
      switch (spacing) {
        case 'Extra Top and Bottom':
          return styles['ctaBlock--extra-top-and-bottom-spacing']
        case 'Extra Top':
          return styles['ctaBlock--extra-top-spacing']
        case 'Extra Bottom':
          return styles['ctaBlock--extra-bottom-spacing']
        default:
          return null
      }
    })(fields.spacing)

    const appStoreLink = fields.layout === 'App Store'
    const dataAttrs = {
      'data-link-type': 'cta',
      'data-link-name': fields.ctaText,
    }

    return (
      <div className={classNames('ctaBlock', styles.ctaBlock, backgroundColorClass, spacingClass)}>
        <Link path={fields.ctaPath} className={styles.ctaBlock__cta} {...dataAttrs}>
          {fields.ctaText}
        </Link>
        <br />
        <br />
        {fields.linkText && (
          <Link path={fields.linkPath} className={styles.ctaBlock__sublink} {...dataAttrs}>
            {fields.linkText}
          </Link>
        )}
        {appStoreLink && (
          <div className={styles.ctaBlock__appStoreLinks}>
            <Link icon={AppleIcon} href="https://appstore.com/vroom" {...dataAttrs} />
            <Link
              icon={AndroidIcon}
              href="https://play.google.com/store/apps/details?id=org.joinvroom.dailyvroom"
              {...dataAttrs}
            />
          </div>
        )}
      </div>
    )
  }
}

export default CtaBlock
